import './PartnersTudor.css';

import { AppContext } from '../../../context/Context';
import { useContext } from 'react';
import locales from '../../../locale/locales.json'
import { partners } from '../list';
import futuristicLines from '../../../svg/design-home-lines.svg'

function PartnersList()
{
    const { lang, isMobile } = useContext(AppContext);

    function populateSection(partnerType="main", small=false)
    {
        let classes = isMobile? "partner-image-mobile" : "partner-image";
        if(isMobile)
            classes += small? " small-partners-mobile" : "";
        else
            classes += small? " small-partners" : "";
    
        return (
        partners[partnerType].map((partner, index) => {
            return (
                <img className={classes} 
                     key={index} 
                     src={isMobile? partner.logoDark : partner.logo}/>
            )
        })
        )
    }


    return (
        <div>
            { !isMobile ?
            <div className="partners-list">
                {/* <img src={futuristicLines}  className="small-design-line-top deg-180"/> */}
                <img src={futuristicLines}  className="big-design-line-top deg-180"/>
                <img src={futuristicLines}  className="big-design-line-bottom"/>
                {/* <img src={futuristicLines}  className="small-design-line-bottom"/> */}
                <div className="partners-main partners">
                    <div className="main-text text-partners"> {locales[lang].partners.mainPartners} </div>
                    <div className="main-wrapper">
                        {
                            populateSection("main")
                        }
                    </div>
                </div>
                <div className="partners-challenge partners">
                    <div className="challenge-text text-partners"> {locales[lang].partners.challengePartners} </div>
                    <div className="challenge-wrapper">
                        {
                            populateSection("challenge", true)
                        }
                    </div>
                </div>

                {/* <div className="partners-sponsors partners">
                    <div className="sponsors-text text-partners"> {locales[lang].partners.sponsorPartners} </div>
                    <div className="sponsors-wrapper">
                        {
                            populateSection("sponsors", true)
                        }
                    </div>
                </div> */}

                <div className="partners-anual partners">
                    <div className="anual-text text-partners"> {locales[lang].partners.anualPartners} </div>
                    <div className="anual-wrapper">
                        {
                            populateSection("anual", true)
                        }
                    </div>
                </div>

                {/* <div className="partners-barter partners">
                    <div className="barter-text text-partners"> {locales[lang].partners.barterPartners} </div>
                    <div className="barter-wrapper">
                        {
                            populateSection("barter", true)
                        }
                    </div>
                </div> */}

            </div>
            :
            <div className="partners-list-mobile">
                {/* <img src={futuristicLines}  className="small-design-line-top-mobile deg-180"/> */}
                <img src={futuristicLines}  className="big-design-line-top-mobile deg-180"/>
                <img src={futuristicLines}  className="big-design-line-bottom-mobile"/>
                {/* <img src={futuristicLines}  className="small-design-line-bottom-mobile"/> */}
                <div className="partners-main partners">
                    <div className="main-text text-partners blue-text"> {locales[lang].partners.mainPartners} </div>
                    <div className="main-wrapper mobile-wrapper">
                        {
                            populateSection("main")
                        }
                    </div>
                </div>
                <div className="partners-challenge partners">
                    <div className="challenge-text text-partners blue-text mobile-subtext"> {locales[lang].partners.challengePartners} </div>
                    <div className="challenge-wrapper mobile-wrapper">
                        {
                            populateSection("challenge", true)
                        }
                    </div>
                </div>

                {/* <div className="partners-sponsors partners">
                    <div className="sponsors-text text-partners blue-text mobile-subtext"> {locales[lang].partners.sponsorPartners} </div>
                    <div className="sponsors-wrapper mobile-wrapper">
                        {
                            populateSection("sponsors", true)
                        }
                    </div>
                </div> */}

                <div className="partners-anual partners">
                    <div className="anual-text text-partners blue-text mobile-subtext"> {locales[lang].partners.anualPartners} </div>
                    <div className="anual-wrapper mobile-wrapper">
                        {
                            populateSection("anual", true)
                        }
                    </div>
                </div>

                {/* <div className="partners-barter partners">
                    <div className="barter-text text-partners blue-text mobile-subtext"> {locales[lang].partners.barterPartners} </div>
                    <div className="barter-wrapper mobile-wrapper">
                        {
                            populateSection("barter", true)
                        }
                    </div>
                </div> */}

            </div>
            }
        </div>
    )
}

export default PartnersList;