export const partners = {
    main: [
      {
        name: "Bitdefender",
        logo: "https://imgur.com/5TRtPg8.png",
        logoDark: "https://imgur.com/5TRtPg8.png",
        url: "https://www.bitdefender.ro/",
      },
      {
        name: "dvloper.io",
        logo: "https://imgur.com/jKQswqu.png",
        logoDark: "https://imgur.com/jKQswqu.png",
        url: "https://dvloper.io/",
      }
    ],
    challenge: [
      {
        name: "Ortec",
        logo: "https://imgur.com/GbGiqVC.png",
        logoDark: "https://imgur.com/U16mnli.png",
        url: "https://www.ortec.com/",
      }
    ],
    sponsors: [
      {
        name: "Ortec",
        logo: "https://imgur.com/GbGiqVC.png",
        logoDark: "https://imgur.com/U16mnli.png",
        url: "https://www.ortec.com/",
      }
    ],
    anual: [
      {
        name: "Mario Pizza",
        logo: "https://imgur.com/nID3tA9.png",
        logoDark: "https://imgur.com/9Loeqkv.png",
        url: "https://mariospizza.ro"
      },
      {
        name: "V7 Startup Studio",
        logo: "https://imgur.com/ebuNhRa.png",
        logoDark: "https://imgur.com/tHfTsjg.png",
        url: "https://v7studio.com/"
      },
      {
        name: "Uncle John",
        logo: "https://imgur.com/zS1ipbq.png",
        logoDark: "https://imgur.com/zS1ipbq.png",
        url: "https://unclejohn.ro"
      }
    ],
    barter: [
      {
        name: "Uncle John",
        logo: "https://imgur.com/zS1ipbq.png",
        logoDark: "https://imgur.com/zS1ipbq.png",
        url: "https://unclejohn.ro"
      }
    ]
  };
  