import "./Home.css";
import logo from "../../svg/EO12.svg";
import futuristicLines from "../../svg/design-home-lines.svg";
import futuristicLinesSimple from "../../svg/futuristic-lines-simple.svg";
import OrangeGradientButton from "../../components/OrangeGradientButton/OrangeGradientButton";
import OrangeGradientText from "../../components/OrangeGradientText/OrangeGradientText";
import locales from "../../locale/locales.json";
import Prize from "./PrizeComponent/Prize";

import { useContext } from "react";
import { AppContext } from "../../context/Context";
import Countdown from "react-countdown";

function Home() {
  const { lang, isMobile } = useContext(AppContext);

  function applyClick() {
    window.location.href = `${locales.both.global.formLink}`;
  }

    function generatePrizes(name)
    {
        return locales[lang].home[name].map((item, index) => {
            return (
            isMobile ?
                <Prize title={item.title} prize={item.money} key={index} />
                :
                <Prize title={item.title} prize={item.money} key={index} fontSize="2.5vw" />
            )
        })
    }

    function generateDates(name)
    {
        return locales[lang].home[name].map((item, index) => {
            return (
            isMobile ?
                <Prize title={item.title} prize={item.date} key={index} />
                :
                <Prize title={item.title} prize={item.date} key={index} fontSize="2.5vw" />
            )
        })
    }

  return (
    <div className="home-container-mobile">
      {isMobile ? (
        <div>
          <div className="main-view-wrapper-mobile">
            <img
              src={futuristicLines}
              className="line-design-main-left-mobile"
            />
            <img
              src={futuristicLines}
              className="line-design-main-center-mobile"
            />
            <img
              src={futuristicLines}
              className="line-design-main-right-mobile"
            />
            <div className="logo-container-mobile">
              <img src={logo} className="logo-main-mobile" />
            </div>
            <div className="apply-wrapper-mobile">

              {/* <OrangeGradientButton
                sizeH="fit-content"
                sizeW="fit-contenct"
                text={locales[lang].home.applyButtonMobile}
                fontSize="6.1vw"
                fancy={true}
              ></OrangeGradientButton> */}

              <OrangeGradientText
                text={locales[lang].home.applyButtonMobile}
                fontSize="4.1vw"
                offset="1px"
                fancy={true}
              />
              
            </div>
            <img
              src={futuristicLines}
              className="line-design-main-bottom-left-mobile"
            />
            <img
              src={futuristicLines}
              className="line-design-main-bottom-center-mobile"
            />
            <img
              src={futuristicLines}
              className="line-design-main-bottom-right-mobile"
            />
          </div>
          <div className="dates-section-mobile">
            {" "}
            <OrangeGradientText
              text={locales[lang].home.datesTitle}
              fontSize="40px"
              fancy={true}
            />
            {generateDates("datesHackathon")}
          </div>

          <img
              className="category-devider-mobile"
              src={futuristicLinesSimple}
            />

          <div className="prize-section-mobile">
            {" "}
            <OrangeGradientText
              text={locales[lang].home.prizeTitle}
              fontSize="48px"
              fancy={true}
            />
            <div className="prize-subtitle-mobile">
              {" "}
              {locales.both.home.prizeSubtitle}{" "}
            </div>
            {generatePrizes("prizesHackathon")}
            <img
              className="category-devider-mobile"
              src={futuristicLinesSimple}
            />
            <div className="prize-subtitle-mobile">
              {" "}
              {locales.both.home.prizeSubtitleChallenge}{" "}
            </div>
            {generatePrizes("prizesChallenge")}
          </div>
        </div>
      ) : (
        <div>
          <div className="main-view-wrapper">
            <img src={futuristicLines} className="line-design-main-top-left" />
            <img
              src={futuristicLines}
              className="line-design-main-middle-left"
            />
            <img
              src={futuristicLines}
              className="line-design-main-bottom-left"
            />
            <div className="logo-container">
              <img src={logo} className="logo-main" />
            </div>
            <div className="apply-wrapper">
              <OrangeGradientButton
                sizeH="fit-content"
                sizeW="fit-contenct"
                text={locales[lang].home.applyButton}
                fontSize="3vw"
                fancy={true}
              ></OrangeGradientButton>
            </div>
            <div className="timer-wrapper">
              <Countdown
                date={new Date(locales.both.home.endOfRegistrationDate)}
                renderer={({ days, hours, minutes }) => (
                  <OrangeGradientText
                    text={`${days}:${hours}:${minutes}`}
                    fontSize="48px"
                    fancy={true}
                  />
                )}
              />
            </div>
            <img src={futuristicLines} className="line-design-main-top-right" />
            <img
              src={futuristicLines}
              className="line-design-main-middle-right"
            />
            <img
              src={futuristicLines}
              className="line-design-main-bottom-right"
            />
          </div>
          <div className="dates-section">
            <OrangeGradientText
              text={locales[lang].home.datesTitle}
              fontSize="80px"
              fancy={true}
            />

            <div className="dates-wrapper">
              {generateDates("datesHackathon")}
            </div>
          </div>
          <div className="prize-section">
            <OrangeGradientText
              text={locales[lang].home.prizeTitle}
              fontSize="80px"
              fancy={true}
            />
            <div className="prize-subtitle">
              {" "}
              {locales.both.home.prizeSubtitle}{" "}
            </div>
            <div className="prizes-wrapper">
              {generatePrizes("prizesHackathonDesktop")}
            </div>
            <div className="prize-subtitle prize-subtitle-less-margin">
              {" "}
              {locales.both.home.prizeSubtitleChallenge}{" "}
            </div>
            <div className="prizes-wrapper">
              {generatePrizes("prizesChallengeDesktop")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
