import  "./Partners.css";
import DesignHomeLines from  "../../svg/design-lines-90-deg.svg"
import TestPhoto from '../../svg/test-photo.svg'
import EoFrame from '../../svg/eo-frame.svg'
import TitleLogo from '../../svg/title-logo.svg'
import FuturisticLinesSimple from '../../svg/futuristic-lines-simple.svg'
import PartnersList from "./components/PartnersTudor";
import {AppContext} from '../../context/Context'
import {useContext} from 'react'
import locales from '../../locale/locales.json'


export default function Partners() 
{
  const { lang, isMobile} = useContext(AppContext);


  return (
    <div>
    { !isMobile ?
    <div class="title-wrapper">
      <div className="title-partners"> { locales[lang].partners.title } </div>
      <img src={DesignHomeLines} class="left design-line" />
      <img src={DesignHomeLines} class="right design-line" style={{ transform: 'rotate(-180deg)' }}/>
      <div class="sponsors">
        <img class="start"src={TitleLogo}/>
        <h2 class="sponsor left-sponsor"> { locales[lang].partners.partner1 } </h2>
      </div>
      <div style={styles.container}>
        <img src={TestPhoto} alt="Background" style={styles.image} />
        <img src={EoFrame} alt="Overlay" style={styles.overlayImage} />
        <div style={styles.textContainer}>
          <p style={styles.text} className="partner-text"> { locales[lang].partners.description1 } </p>
        </div>
      </div>
      <div class="sponsors">
        <img class="end" src={TitleLogo}/>
        <div class="right-text">{ locales[lang].partners.partner2 }</div>
      </div>
      <div style={styles.container} className="second-image">
        <img src={TestPhoto} alt="Background" style={styles.image} />
        <img src={EoFrame} alt="Overlay" style={styles.overlayImage} />
        <div style={styles.textContainer}>
          <p style={styles.text} className="partner-text"> { locales[lang].partners.description2 } </p>
        </div>
      </div>
      <div class="flex" style={{marginTop:"60px"}}>
        <img src={FuturisticLinesSimple} style={{width:'40%'}}/>
        <p class="center"> {locales[lang].partners.financialPartners} </p>
        <img src={FuturisticLinesSimple} style={{ width:'40%' , transform: 'rotate(-180deg)' }}/>
      </div>
      <PartnersList/>
    </div>

      :

    <div class="title-wrapper-mobile">
      <div className="title-partners"> { locales[lang].partners.title } </div>
      <img src={DesignHomeLines} class="left-mobile design-line" />
      <img src={DesignHomeLines} class="right-mobile design-line" style={{ transform: 'rotate(-180deg)' }}/>
      <div class="sponsors">
        <img class="start"src={TitleLogo}/>
        <h2 class="left-sponsor-mobile"> { locales[lang].partners.partner1 } </h2>
      </div>
      <div style={styles.container}>
        <img src={TestPhoto} alt="Background" style={styles.image} />
        <img src={EoFrame} alt="Overlay" style={styles.overlayImage} />
        <div style={styles.textContainer}>
          <p style={styles.text} className="partner-text-mobile"> { locales[lang].partners.description1 } </p>
        </div>
      </div>
      <div class="sponsors">
        <img class="end-mobile" src={TitleLogo}/>
        <div class="right-text-mobile">{ locales[lang].partners.partner2 }</div>
      </div>
      <div style={styles.container} className="second-image">
        <img src={TestPhoto} alt="Background" style={styles.image} />
        <img src={EoFrame} alt="Overlay" style={styles.overlayImage} />
        <div style={styles.textContainer}>
          <p style={styles.text} className="partner-text-mobile"> { locales[lang].partners.description2 } </p>
        </div>
      </div>
      <div className="flex" style={{marginTop:"60px"}}>
        <img src={FuturisticLinesSimple} style={{width:'32%'}}/>
        <p className="center-mobile"> {locales[lang].partners.financialPartners} </p>
        <img src={FuturisticLinesSimple} style={{ width:'32%' , transform: 'rotate(-180deg)' }}/>
      </div>
      <PartnersList/>
    </div>
    }
  </div>
  );
} 

const styles = {
  container: {
    position: 'relative',
    height: '30%',
  },
  image: {
    width: '100%',
    height: '50%',
    objectFit: 'cover',
    opacity:0.5,
    filter: 'brightness(50%)'
  },
  overlayImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'white', 
    textAlign: 'left',
    marginTop: '20px'
  },
};