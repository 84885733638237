import './OrangeGradientButton.css'
import OrangeGradientText from '../OrangeGradientText/OrangeGradientText'

function OrangeGradientButton({ onClick, sizeH, sizeW, text, fontSize, fancy = false })
{
    return (
        <div className="button-wrapper" onClick={onClick}>
            <div className="orange-gradient-button" style={{height: sizeH, width: sizeW}}>
                <OrangeGradientText text={text} fontSize={fontSize}/>
            </div>
            {
                fancy ? <div className="button-background" style={{height: sizeH, width: sizeW}}>
                    <OrangeGradientText text={text} fontSize={fontSize}/>
                </div> : null
            }
        </div>
    )
}


export default OrangeGradientButton